.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60vw;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
  min-width: 380px;
}
