.signup {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  width: 70%;
  margin: 0 auto;
  padding: 4%;
  flex-wrap: nowrap !important;
  align-items: center;
}

.signup div {
  margin: 5px;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  align-items: center;
}

.submit {
  display: flex;
  position: relative;
  justify-content: center;
}

.loading {
  position: absolute;
}

#country,
#scubaLevel,
#certAgency {
  padding: 4px;
}
