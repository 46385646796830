.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0px;
}
.social-media {
  width: 2vw;
  align-self: flex-end;
}
.icon {
  padding: 10px;
}
