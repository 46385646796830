.signup-completed {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  width: 70%;
  margin: 0 auto;
  padding: 4%;
  flex-wrap: nowrap !important;
  align-items: center;
  text-align: center;
}
