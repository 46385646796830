.body {
  display: flex;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 2vw;
}

.registration-button {
  display: flex;
  justify-content: center;
}
