.header {
  display: flex;
  padding: 1vw;
  align-items: center;
  flex-direction: column;
}

.logo {
  padding: 1vw;
  border-radius: 10px;
  border: 2px inset;
  width: 10vw;
}

.menu {
  display: flex;
  width: 40vw;
  justify-content: space-between;
  margin: 1vw;
  max-width: 300px;
}

.navLink {
  text-decoration: none;
  color: white;
  opacity: 50%;
}

.navLink.active {
  opacity: 100%;
}

.navLink:hover {
  opacity: 100%;
}

.navLink button {
  border-radius: 5px;
  min-height: 20px;
  padding: 0;
}
