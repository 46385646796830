.card {
  max-width: 150px;
  max-height: 100px;
  margin: 5%;
}
.wide {
  width: 100%;
  max-width: 100%;
  margin: 10px auto;
}
